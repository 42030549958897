<template>
    <div class="tabs-wrapper">
        <p class="subtitle">Вид заказчика</p>

        <div class="tabs js-tabs">
            <div class="tabs__items">
                <RouterLink
                    v-for="type in userTypes"
                    :key="type.user_type.id"
                    :to="{name: 'application-registration-' + type.user_type.code}"
                    active-class="active"
                    class="tabs__item">
                    {{ type.user_type.name }}
                </RouterLink>
            </div>
        </div>
        <p class="subtitle"><span class="subtitle text-red">Шаг 1.&nbsp;</span>Заполните реквизиты для формирования договора</p>

        <RouterView></RouterView>

        <div class="section-brief">
            <p>При получении проекта договора и необходимости выполнения корректировки с вашей стороны, вы можете:</p>
            <div class="section-brief__list">
                <div class="section-brief__item">сохранить договор в редакторе на ваш ПК;</div>
                <div class="section-brief__item">выполнить редакцию;</div>
                <div class="section-brief__item">направить на наш электронный адрес:&nbsp;<a href="mailto:info@mait-nauka.ru" class="section-brief__link">
                    <svg class="icon icon-email ">
                        <use xlink:href="#email"></use>
                    </svg>
                    <span>info@mait-nauka.ru</span></a>
                </div>
            </div>
            <div class="text-gray">Ваша корректура (редакция) будет принята при направлении оригинала договора на ваш почтовый адрес.</div>
        </div>
    </div>
</template>

<script>
import store from '@/store/index.js'

export default {
    name: "Application",

    beforeRouteEnter(to, from, next) {
        store.dispatch('APPLICATION_GET_FIELDS').then(() => {next()})
    },

    data() {
        return {
            users: []
        }
    },

    computed: {
        userTypes() {
            return this.$store.state.application.usersTypes
        }
    },
}
</script>
